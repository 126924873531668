.react-pdf__Page__canvas {
  margin: 20px auto;
  transition: opacity ease-in-out 0.2s
}

.react-pdf__Document {
  position: relative;
  margin: auto;
  transition: opacity ease-in-out 0.2s
}
.react-pdf__Document:hover .page-controls {
  opacity: 1;
}

.react-pdf__Document:hover .download-button {
  opacity: 1;
}

.page-controls button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-controls button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.react-pdf__Page.loadingPage {
  display: none;
}
